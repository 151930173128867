<template>
  <div class="row gy-5 g-xl-8">

  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
export default defineComponent({
  name: "dashboard",
  setup() {
    const store = useStore();

    if (store.getters.getStatusLogin === true) {
      location.reload();
    }
    onMounted(() => {
      setCurrentPageBreadcrumbs("หน้าหลัก");
    });


  },
});
</script>

<style></style>
